// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About_about__nXrac {
	width: 100vw;
	padding: 6.25rem 10.31rem;
	display: flex;
	justify-content: space-between;
}

.About_text__side__M8pXZ {
	width: 50%;
	display: flex;
	flex-direction: column;
	gap: 3.3rem;
}

@media (max-width: 1280px) {
	.About_about__nXrac {
		padding: 6.25rem 5rem;
	}
}

@media (max-width: 460px) {
	.About_about__nXrac {
		flex-direction: column;
		padding: 4.38rem 1.25rem;
		align-items: center;
	}

	.About_about__nXrac img {
		display: none;
	}
	.About_text__side__M8pXZ {
		width: 100%;
		align-items: center;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/DefaultPage/About/About.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,yBAAyB;CACzB,aAAa;CACb,8BAA8B;AAC/B;;AAEA;CACC,UAAU;CACV,aAAa;CACb,sBAAsB;CACtB,WAAW;AACZ;;AAEA;CACC;EACC,qBAAqB;CACtB;AACD;;AAEA;CACC;EACC,sBAAsB;EACtB,wBAAwB;EACxB,mBAAmB;CACpB;;CAEA;EACC,aAAa;CACd;CACA;EACC,WAAW;EACX,mBAAmB;CACpB;AACD","sourcesContent":[".about {\n\twidth: 100vw;\n\tpadding: 6.25rem 10.31rem;\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.text__side {\n\twidth: 50%;\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 3.3rem;\n}\n\n@media (max-width: 1280px) {\n\t.about {\n\t\tpadding: 6.25rem 5rem;\n\t}\n}\n\n@media (max-width: 460px) {\n\t.about {\n\t\tflex-direction: column;\n\t\tpadding: 4.38rem 1.25rem;\n\t\talign-items: center;\n\t}\n\n\t.about img {\n\t\tdisplay: none;\n\t}\n\t.text__side {\n\t\twidth: 100%;\n\t\talign-items: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": `About_about__nXrac`,
	"text__side": `About_text__side__M8pXZ`
};
export default ___CSS_LOADER_EXPORT___;
