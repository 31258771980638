// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReservationPage_reservation__page__HjngK {
	width: 100%;
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 1.5rem;

	color: var(--accent-color, #875b52);

	/* text small */
	font-family: 'Noto Sans';
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 1.6rem */
}
`, "",{"version":3,"sources":["webpack://./src/Components/ReservationPage/ReservationPage.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,gBAAgB;CAChB,aAAa;CACb,sBAAsB;CACtB,mBAAmB;;CAEnB,WAAW;;CAEX,mCAAmC;;CAEnC,eAAe;CACf,wBAAwB;CACxB,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB,EAAE,WAAW;AAC/B","sourcesContent":[".reservation__page {\n\twidth: 100%;\n\tmin-height: 40vh;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\n\tgap: 1.5rem;\n\n\tcolor: var(--accent-color, #875b52);\n\n\t/* text small */\n\tfont-family: 'Noto Sans';\n\tfont-size: 1rem;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 160%; /* 1.6rem */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reservation__page": `ReservationPage_reservation__page__HjngK`
};
export default ___CSS_LOADER_EXPORT___;
