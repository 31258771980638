// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Gallery_gallery__QinKQ {
	padding: 6.25rem 10.31rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3rem;
}

.Gallery_gallery__images__XvWgW {
	width: 100%;
	display: flex;
	row-gap: 1.25rem;
	column-gap: 1.25rem;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.Gallery_gallery__img__obq8W {
	width: 21.875rem;
	height: 15.625rem;
	cursor: pointer;
}

.Gallery_gallery__slider__nfX0K {
	width: 100%;
	display: flex;
	align-items: center;

	justify-content: space-between;
	display: none;
}

.Gallery_slider__button__Go9Zp {
	display: flex;
	align-self: center;
	justify-content: center;
	height: 100%;
	width: 5%;
}

@media (max-width: 1280px) {
	.Gallery_gallery__QinKQ {
		padding: 6.25rem 5rem;
	}
}

@media (max-width: 460px) {
	.Gallery_gallery__QinKQ {
		flex-direction: column;
		padding: 4.38rem 1.25rem;
		align-items: center;
	}
	.Gallery_gallery__images__XvWgW {
		display: none;
	}
	.Gallery_gallery__slider__nfX0K {
		display: flex;
		height: 13rem;
		align-items: center;
	}
	.Gallery_gallery__slider__nfX0K img {
		width: 17.8125rem;
	}
	.Gallery_slider__button__Go9Zp {
		width: 8%;
		align-items: center;
	}
}

@media (max-width: 360px) {
	.Gallery_gallery__slider__nfX0K img {
		width: 15rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/DefaultPage/Gallery/Gallery.module.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,SAAS;AACV;;AAEA;CACC,WAAW;CACX,aAAa;CACb,gBAAgB;CAChB,mBAAmB;CACnB,eAAe;CACf,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,gBAAgB;CAChB,iBAAiB;CACjB,eAAe;AAChB;;AAEA;CACC,WAAW;CACX,aAAa;CACb,mBAAmB;;CAEnB,8BAA8B;CAC9B,aAAa;AACd;;AAEA;CACC,aAAa;CACb,kBAAkB;CAClB,uBAAuB;CACvB,YAAY;CACZ,SAAS;AACV;;AAEA;CACC;EACC,qBAAqB;CACtB;AACD;;AAEA;CACC;EACC,sBAAsB;EACtB,wBAAwB;EACxB,mBAAmB;CACpB;CACA;EACC,aAAa;CACd;CACA;EACC,aAAa;EACb,aAAa;EACb,mBAAmB;CACpB;CACA;EACC,iBAAiB;CAClB;CACA;EACC,SAAS;EACT,mBAAmB;CACpB;AACD;;AAEA;CACC;EACC,YAAY;CACb;AACD","sourcesContent":[".gallery {\n\tpadding: 6.25rem 10.31rem;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tgap: 3rem;\n}\n\n.gallery__images {\n\twidth: 100%;\n\tdisplay: flex;\n\trow-gap: 1.25rem;\n\tcolumn-gap: 1.25rem;\n\tflex-wrap: wrap;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.gallery__img {\n\twidth: 21.875rem;\n\theight: 15.625rem;\n\tcursor: pointer;\n}\n\n.gallery__slider {\n\twidth: 100%;\n\tdisplay: flex;\n\talign-items: center;\n\n\tjustify-content: space-between;\n\tdisplay: none;\n}\n\n.slider__button {\n\tdisplay: flex;\n\talign-self: center;\n\tjustify-content: center;\n\theight: 100%;\n\twidth: 5%;\n}\n\n@media (max-width: 1280px) {\n\t.gallery {\n\t\tpadding: 6.25rem 5rem;\n\t}\n}\n\n@media (max-width: 460px) {\n\t.gallery {\n\t\tflex-direction: column;\n\t\tpadding: 4.38rem 1.25rem;\n\t\talign-items: center;\n\t}\n\t.gallery__images {\n\t\tdisplay: none;\n\t}\n\t.gallery__slider {\n\t\tdisplay: flex;\n\t\theight: 13rem;\n\t\talign-items: center;\n\t}\n\t.gallery__slider img {\n\t\twidth: 17.8125rem;\n\t}\n\t.slider__button {\n\t\twidth: 8%;\n\t\talign-items: center;\n\t}\n}\n\n@media (max-width: 360px) {\n\t.gallery__slider img {\n\t\twidth: 15rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery": `Gallery_gallery__QinKQ`,
	"gallery__images": `Gallery_gallery__images__XvWgW`,
	"gallery__img": `Gallery_gallery__img__obq8W`,
	"gallery__slider": `Gallery_gallery__slider__nfX0K`,
	"slider__button": `Gallery_slider__button__Go9Zp`
};
export default ___CSS_LOADER_EXPORT___;
