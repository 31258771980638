import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function RulesPage() {
	window.scrollTo(0, 0);
	return (
		<div>
			<Header main={false} />
			<div className="block__page">
				<div className="block__title">
					<div className="title__line"></div>Правила бронирования и проживания
					<div className="title__line"></div>
				</div>
				<div className="block__page_text">
					<span>Правила бронирования и проживания в отеле «Счастье»:</span>
					<span>
						1. Бронирование номера: Для бронирования номера в отеле «Счастье» необходимо воспользоваться
						онлайн-бронированием на нашем официальном сайте или связаться с нами по указанному телефлну
						для получения дополнительной информации и подтверждения бронирования. Мы принимаем бронирования
						как заранее, так и на месте, в зависимости от наличия свободных номеров
					</span>
					<span>
						2. Информация о бронировании: При бронировании номера, пожалуйста, предоставьте нам следующую
						информацию: ваше имя, фамилию, телефон, даты пребывания и предпочтения относительно типа номера.
					</span>
					<span>
						3. Подтверждение бронирования: Мы подтверждаем бронирование номера посредством устной
						коммуникации с вами. Если вы воспользовались онлайн-бронированием, мы вам перезвоним.
					</span>
					<span>
						4. Отмена бронирования: В случае необходимости отменить бронирование, пожалуйста, сообщите
						нам об этом как можно скорее. Мы рекомендуем отменять бронирование не менее чем за 24 часа
						до запланированной даты прибытия.
					</span>
					<span>
						5. Оплата проживания: Мы не предлагаем онлайн оплату и принимаем оплату только наличными
						или кредитными картами по прибытии в отель. Пожалуйста, имейте в виду, что мы не принимаем чеки
						или другие формы оплаты.
					</span>
					<span>
						6. Правила проживания:
						<ul className="dot__list">
							<li>Дети до 4 лет размещаются бесплатно и также являются гостями.</li>
							<li>У нас можно проживать с кошками и небольшими собаками.</li>
							<li>У нас ЗАПРЕЩЕНО курить и пользоваться открытым огнём.</li>
							<li>Запрещается выносить на улицу полотенца и постельное белье.</li>
							<li>Администрация не несёт ответственности за ваши ценные вещи и имущество.</li>
						</ul>
					</span>
					<span>
						7. Заезд и выезд: Просьба освободить номер до указанного времени выезда, чтобы мы могли
						подготовить его к следующему гостю. Если вам нужно продлить пребывание, пожалуйста, свяжитесь
						с нами заранее, чтобы проверить наличие свободных номеров. Поздний заезд или ранний выезд
						не влияют на стоимость проживания, но должны согласовываться с менеджером по бронированию
					</span>
					<span>
						8. Осуществляя бронирование тем или иным способом, вы соглашаетесь со всеми правилами
						и условиями проживания и полностью несёте ответственность за себя и своих близких в период
						проживания в отеле.
					</span>
				</div>
			</div>
			<Footer main={false} />
		</div>
	);
}

export default RulesPage;
