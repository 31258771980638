// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalStatus_modal__body__6eW77 {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1.87rem;
	flex-direction: column;
	padding: 4.38rem;
}

.ModalStatus_modal__content__461KD {
	width: 36.125rem;
	height: 23.125rem;
	margin: 50% 0 0;
}

.ModalStatus_modal__body__6eW77 span {
	font-family: 'Noto Sans';
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 500;
	line-height: 160%; /* 2.2rem */
}

.ModalStatus_close__button__rG-10 path {
	fill: var(--accent-color);
}

@media (max-width: 460px) {
	.ModalStatus_modal__body__6eW77 {
		width: 100%;
		padding: 4.38rem 2rem;
	}
	.ModalStatus_modal__content__461KD {
		margin: 1.25rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/ModalStatus/ModalStatus.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,YAAY;CACZ,sBAAsB;CACtB,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;CAChB,iBAAiB;CACjB,eAAe;AAChB;;AAEA;CACC,wBAAwB;CACxB,mBAAmB;CACnB,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB,EAAE,WAAW;AAC/B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC;EACC,WAAW;EACX,qBAAqB;CACtB;CACA;EACC,eAAe;CAChB;AACD","sourcesContent":[".modal__body {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tgap: 1.87rem;\n\tflex-direction: column;\n\tpadding: 4.38rem;\n}\n\n.modal__content {\n\twidth: 36.125rem;\n\theight: 23.125rem;\n\tmargin: 50% 0 0;\n}\n\n.modal__body span {\n\tfont-family: 'Noto Sans';\n\tfont-size: 1.375rem;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tline-height: 160%; /* 2.2rem */\n}\n\n.close__button path {\n\tfill: var(--accent-color);\n}\n\n@media (max-width: 460px) {\n\t.modal__body {\n\t\twidth: 100%;\n\t\tpadding: 4.38rem 2rem;\n\t}\n\t.modal__content {\n\t\tmargin: 1.25rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal__body": `ModalStatus_modal__body__6eW77`,
	"modal__content": `ModalStatus_modal__content__461KD`,
	"close__button": `ModalStatus_close__button__rG-10`
};
export default ___CSS_LOADER_EXPORT___;
