// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rooms_rooms__f58y8 {
	padding: 6.25rem 10.31rem;
	display: flex;
	flex-direction: column;

	gap: 4.5rem;
}

.Rooms_rooms__book__lNKqB {
	padding: 1.9rem 0rem;
	display: flex;
	flex-wrap: wrap;

	gap: 1.88rem;
	row-gap: 4.37rem;

	width: 100%;
	justify-content: center;
}

@media (max-width: 1280px) {
	.Rooms_rooms__f58y8 {
		padding: 6.25rem 5rem;
	}
	.Rooms_rooms_book__rbGke {
		padding: 1.9rem 0rem;
	}
}

@media (max-width: 460px) {
	.Rooms_rooms__f58y8 {
		flex-direction: column;
		padding: 4.38rem 1.25rem;
		align-items: center;
	}
	.Rooms_rooms__book__lNKqB {
		flex-direction: column;
		align-items: center;
	}

	.Rooms_about__lXvLz img {
		display: none;
	}
	.Rooms_text__side__6dlmD {
		width: 100%;
		align-items: center;
	}
	.Rooms_preferences__items__QFNwi {
		flex-wrap: wrap;
		gap: 1.25rem;
	}
	.Rooms_item__C8VDD {
		width: 10rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/DefaultPage/Rooms/Rooms.module.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,aAAa;CACb,sBAAsB;;CAEtB,WAAW;AACZ;;AAEA;CACC,oBAAoB;CACpB,aAAa;CACb,eAAe;;CAEf,YAAY;CACZ,gBAAgB;;CAEhB,WAAW;CACX,uBAAuB;AACxB;;AAEA;CACC;EACC,qBAAqB;CACtB;CACA;EACC,oBAAoB;CACrB;AACD;;AAEA;CACC;EACC,sBAAsB;EACtB,wBAAwB;EACxB,mBAAmB;CACpB;CACA;EACC,sBAAsB;EACtB,mBAAmB;CACpB;;CAEA;EACC,aAAa;CACd;CACA;EACC,WAAW;EACX,mBAAmB;CACpB;CACA;EACC,eAAe;EACf,YAAY;CACb;CACA;EACC,YAAY;CACb;AACD","sourcesContent":[".rooms {\n\tpadding: 6.25rem 10.31rem;\n\tdisplay: flex;\n\tflex-direction: column;\n\n\tgap: 4.5rem;\n}\n\n.rooms__book {\n\tpadding: 1.9rem 0rem;\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\n\tgap: 1.88rem;\n\trow-gap: 4.37rem;\n\n\twidth: 100%;\n\tjustify-content: center;\n}\n\n@media (max-width: 1280px) {\n\t.rooms {\n\t\tpadding: 6.25rem 5rem;\n\t}\n\t.rooms_book {\n\t\tpadding: 1.9rem 0rem;\n\t}\n}\n\n@media (max-width: 460px) {\n\t.rooms {\n\t\tflex-direction: column;\n\t\tpadding: 4.38rem 1.25rem;\n\t\talign-items: center;\n\t}\n\t.rooms__book {\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t}\n\n\t.about img {\n\t\tdisplay: none;\n\t}\n\t.text__side {\n\t\twidth: 100%;\n\t\talign-items: center;\n\t}\n\t.preferences__items {\n\t\tflex-wrap: wrap;\n\t\tgap: 1.25rem;\n\t}\n\t.item {\n\t\twidth: 10rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rooms": `Rooms_rooms__f58y8`,
	"rooms__book": `Rooms_rooms__book__lNKqB`,
	"rooms_book": `Rooms_rooms_book__rbGke`,
	"about": `Rooms_about__lXvLz`,
	"text__side": `Rooms_text__side__6dlmD`,
	"preferences__items": `Rooms_preferences__items__QFNwi`,
	"item": `Rooms_item__C8VDD`
};
export default ___CSS_LOADER_EXPORT___;
