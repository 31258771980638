// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_primary__btn__s1ipS {
	width: 13.4375rem;

	border-radius: 0.5rem;
	background: var(--accent-color, #875b52);

	/* btn primary */
	box-shadow: -2px 4px 5px 0px rgba(148, 93, 60, 0.06);
	padding: 0.75rem 2.44rem;

	color: var(--text-color, #fff);
	text-align: center;

	/* text small semibold */
	font-family: 'Noto Sans';
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	line-height: 160%; /* 1.6rem */

	margin-top: 3.12rem;
}

.Form_checkbox__-c5sR {
	display: flex;
	gap: 0.3rem;
	color: var(--accent-color, #875b52);

	/* text small */
	font-family: 'Noto Sans';
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 1.6rem */
}

.Form_form__\\+fVZe {
	width: 33.8125rem;
	height: 47.125rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	border-radius: 0.5rem;
	border: 1px solid var(--ui-accent, #875b52);
	background: var(--ui-white, #fff);

	padding: 3.13rem 4.1rem;
}

.Form_underline__text__t4xb7 {
	text-decoration: underline;
}

@media (max-width: 460px) {
	.Form_form__\\+fVZe {
		width: 100%;
		height: auto;
		padding: 2rem;
	}
	.Form_primary__btn__s1ipS {
		margin-top: 7rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/Form/Form.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;;CAEjB,qBAAqB;CACrB,wCAAwC;;CAExC,gBAAgB;CAChB,oDAAoD;CACpD,wBAAwB;;CAExB,8BAA8B;CAC9B,kBAAkB;;CAElB,wBAAwB;CACxB,wBAAwB;CACxB,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB,EAAE,WAAW;;CAE9B,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,WAAW;CACX,mCAAmC;;CAEnC,eAAe;CACf,wBAAwB;CACxB,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB,EAAE,WAAW;AAC/B;;AAEA;CACC,iBAAiB;CACjB,iBAAiB;CACjB,aAAa;CACb,sBAAsB;CACtB,WAAW;;CAEX,qBAAqB;CACrB,2CAA2C;CAC3C,iCAAiC;;CAEjC,uBAAuB;AACxB;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC;EACC,WAAW;EACX,YAAY;EACZ,aAAa;CACd;CACA;EACC,gBAAgB;CACjB;AACD","sourcesContent":[".primary__btn {\n\twidth: 13.4375rem;\n\n\tborder-radius: 0.5rem;\n\tbackground: var(--accent-color, #875b52);\n\n\t/* btn primary */\n\tbox-shadow: -2px 4px 5px 0px rgba(148, 93, 60, 0.06);\n\tpadding: 0.75rem 2.44rem;\n\n\tcolor: var(--text-color, #fff);\n\ttext-align: center;\n\n\t/* text small semibold */\n\tfont-family: 'Noto Sans';\n\tfont-size: 1rem;\n\tfont-style: normal;\n\tfont-weight: 600;\n\tline-height: 160%; /* 1.6rem */\n\n\tmargin-top: 3.12rem;\n}\n\n.checkbox {\n\tdisplay: flex;\n\tgap: 0.3rem;\n\tcolor: var(--accent-color, #875b52);\n\n\t/* text small */\n\tfont-family: 'Noto Sans';\n\tfont-size: 1rem;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 160%; /* 1.6rem */\n}\n\n.form {\n\twidth: 33.8125rem;\n\theight: 47.125rem;\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 0.5rem;\n\n\tborder-radius: 0.5rem;\n\tborder: 1px solid var(--ui-accent, #875b52);\n\tbackground: var(--ui-white, #fff);\n\n\tpadding: 3.13rem 4.1rem;\n}\n\n.underline__text {\n\ttext-decoration: underline;\n}\n\n@media (max-width: 460px) {\n\t.form {\n\t\twidth: 100%;\n\t\theight: auto;\n\t\tpadding: 2rem;\n\t}\n\t.primary__btn {\n\t\tmargin-top: 7rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary__btn": `Form_primary__btn__s1ipS`,
	"checkbox": `Form_checkbox__-c5sR`,
	"form": `Form_form__+fVZe`,
	"underline__text": `Form_underline__text__t4xb7`
};
export default ___CSS_LOADER_EXPORT___;
