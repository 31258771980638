// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage_page__title__YhQWJ {
	color: var(--ui-accent, #875b52);
	text-align: center;
	/* H1 */
	font-family: 'Cormorant';
	font-size: 4.6875rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.ErrorPage_page__text__hj2U- {
	color: var(--accent-color, #875b52);
	text-align: center;

	/* text large */
	font-family: 'Noto Sans';
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 500;
	line-height: 160%; /* 2.2rem */
	margin-bottom: 5rem;
}

.ErrorPage_underline__text__FVyBD {
	color: var(--accent-color, #875b52);
	font-family: 'Noto Sans';
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
	text-decoration-line: underline;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ErrorPage/ErrorPage.module.css"],"names":[],"mappings":"AAAA;CACC,gCAAgC;CAChC,kBAAkB;CAClB,OAAO;CACP,wBAAwB;CACxB,oBAAoB;CACpB,kBAAkB;CAClB,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,mCAAmC;CACnC,kBAAkB;;CAElB,eAAe;CACf,wBAAwB;CACxB,mBAAmB;CACnB,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB,EAAE,WAAW;CAC9B,mBAAmB;AACpB;;AAEA;CACC,mCAAmC;CACnC,wBAAwB;CACxB,mBAAmB;CACnB,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB;CACjB,+BAA+B;AAChC","sourcesContent":[".page__title {\n\tcolor: var(--ui-accent, #875b52);\n\ttext-align: center;\n\t/* H1 */\n\tfont-family: 'Cormorant';\n\tfont-size: 4.6875rem;\n\tfont-style: normal;\n\tfont-weight: 600;\n\tline-height: normal;\n}\n\n.page__text {\n\tcolor: var(--accent-color, #875b52);\n\ttext-align: center;\n\n\t/* text large */\n\tfont-family: 'Noto Sans';\n\tfont-size: 1.375rem;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tline-height: 160%; /* 2.2rem */\n\tmargin-bottom: 5rem;\n}\n\n.underline__text {\n\tcolor: var(--accent-color, #875b52);\n\tfont-family: 'Noto Sans';\n\tfont-size: 1.375rem;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tline-height: 160%;\n\ttext-decoration-line: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page__title": `ErrorPage_page__title__YhQWJ`,
	"page__text": `ErrorPage_page__text__hj2U-`,
	"underline__text": `ErrorPage_underline__text__FVyBD`
};
export default ___CSS_LOADER_EXPORT___;
