// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner_spinner__GpH8e {
	width: 100%;
	height: 15rem;

	display: flex;
	justify-content: center;
	align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/Spinner/Spinner.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,aAAa;;CAEb,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":[".spinner {\n\twidth: 100%;\n\theight: 15rem;\n\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `Spinner_spinner__GpH8e`
};
export default ___CSS_LOADER_EXPORT___;
