// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Services_services__j9qHO {
	background: var(--grey-color, #fff8f6);
	width: 100%;
	padding: 6.25rem 0;
	display: flex;
	flex-direction: column;

	align-items: center;

	gap: 4rem;
}

.Services_services__without__bg__gCGFb {
	width: 100%;
	padding: 6.25rem 0;
	display: flex;
	flex-direction: column;

	align-items: center;

	gap: 4rem;
}

.Services_services__list__sEW3q {
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
}

.Services_list__item__ASWdk {
	display: flex;
	align-items: center;
	gap: 1rem;

	color: var(--accent-color, #956446);

	/* text small */
	font-family: 'Noto Sans';
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 1.6rem */
}

.Services_bold__text__lfpzE {
	font-weight: 700;
	line-height: 160%;
}

@media (max-width: 460px) {
	.Services_services__j9qHO {
		flex-direction: column;
		padding: 4.38rem 1.25rem;
		align-items: center;
	}
	.Services_list__item__ASWdk svg {
		width: 1.5625rem;
		height: 1.56256rem;
	}
	.Services_list__item__ASWdk {
		width: 100%;
		display: flex;
		gap: 0.8rem;
		font-size: 0.9375rem;
		align-items: start;
		align-items: flex-start;
	}
	.Services_list__item__ASWdk span {
		width: 80%;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/DefaultPage/ServicesBlock/Services.module.css"],"names":[],"mappings":"AAAA;CACC,sCAAsC;CACtC,WAAW;CACX,kBAAkB;CAClB,aAAa;CACb,sBAAsB;;CAEtB,mBAAmB;;CAEnB,SAAS;AACV;;AAEA;CACC,WAAW;CACX,kBAAkB;CAClB,aAAa;CACb,sBAAsB;;CAEtB,mBAAmB;;CAEnB,SAAS;AACV;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,YAAY;AACb;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,SAAS;;CAET,mCAAmC;;CAEnC,eAAe;CACf,wBAAwB;CACxB,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB,EAAE,WAAW;AAC/B;;AAEA;CACC,gBAAgB;CAChB,iBAAiB;AAClB;;AAEA;CACC;EACC,sBAAsB;EACtB,wBAAwB;EACxB,mBAAmB;CACpB;CACA;EACC,gBAAgB;EAChB,kBAAkB;CACnB;CACA;EACC,WAAW;EACX,aAAa;EACb,WAAW;EACX,oBAAoB;EACpB,kBAAkB;EAClB,uBAAuB;CACxB;CACA;EACC,UAAU;CACX;AACD","sourcesContent":[".services {\n\tbackground: var(--grey-color, #fff8f6);\n\twidth: 100%;\n\tpadding: 6.25rem 0;\n\tdisplay: flex;\n\tflex-direction: column;\n\n\talign-items: center;\n\n\tgap: 4rem;\n}\n\n.services__without__bg {\n\twidth: 100%;\n\tpadding: 6.25rem 0;\n\tdisplay: flex;\n\tflex-direction: column;\n\n\talign-items: center;\n\n\tgap: 4rem;\n}\n\n.services__list {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 1.25rem;\n}\n\n.list__item {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 1rem;\n\n\tcolor: var(--accent-color, #956446);\n\n\t/* text small */\n\tfont-family: 'Noto Sans';\n\tfont-size: 1rem;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 160%; /* 1.6rem */\n}\n\n.bold__text {\n\tfont-weight: 700;\n\tline-height: 160%;\n}\n\n@media (max-width: 460px) {\n\t.services {\n\t\tflex-direction: column;\n\t\tpadding: 4.38rem 1.25rem;\n\t\talign-items: center;\n\t}\n\t.list__item svg {\n\t\twidth: 1.5625rem;\n\t\theight: 1.56256rem;\n\t}\n\t.list__item {\n\t\twidth: 100%;\n\t\tdisplay: flex;\n\t\tgap: 0.8rem;\n\t\tfont-size: 0.9375rem;\n\t\talign-items: start;\n\t\talign-items: flex-start;\n\t}\n\t.list__item span {\n\t\twidth: 80%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"services": `Services_services__j9qHO`,
	"services__without__bg": `Services_services__without__bg__gCGFb`,
	"services__list": `Services_services__list__sEW3q`,
	"list__item": `Services_list__item__ASWdk`,
	"bold__text": `Services_bold__text__lfpzE`
};
export default ___CSS_LOADER_EXPORT___;
