// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalImages_modal__arrow__r6kzj {
	cursor: pointer;
	height: 100%;
	width: 3rem;
	-webkit-user-select: none;
	        user-select: none;
}

.ModalImages_modal__img__NBdQR {
	height: 100%;
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ModalImages_modal__body__akP0u {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.ModalImages_close__button__UWPi0 path {
	fill: var(--accent-color);
}

@media (max-width: 460px) {
	.ModalImages_modal__body__akP0u {
		justify-content: space-between;
	}
	.ModalImages_modal__img__NBdQR {
		width: 25rem;
	}
	.ModalImages_modal__body__akP0u {
		justify-content: space-between;
		padding: 0.5rem;
		padding-inline-start: none;
		padding-inline-end: none;
	}
	.ModalImages_modal__arrow__r6kzj {
		width: 3rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/ModalImages/ModalImages.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,YAAY;CACZ,WAAW;CACX,yBAAiB;SAAjB,iBAAiB;AAClB;;AAEA;CACC,YAAY;CACZ,UAAU;CACV,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,6BAA6B;AAC9B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC;EACC,8BAA8B;CAC/B;CACA;EACC,YAAY;CACb;CACA;EACC,8BAA8B;EAC9B,eAAe;EACf,0BAA0B;EAC1B,wBAAwB;CACzB;CACA;EACC,WAAW;CACZ;AACD","sourcesContent":[".modal__arrow {\n\tcursor: pointer;\n\theight: 100%;\n\twidth: 3rem;\n\tuser-select: none;\n}\n\n.modal__img {\n\theight: 100%;\n\twidth: 80%;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.modal__body {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-around;\n}\n\n.close__button path {\n\tfill: var(--accent-color);\n}\n\n@media (max-width: 460px) {\n\t.modal__body {\n\t\tjustify-content: space-between;\n\t}\n\t.modal__img {\n\t\twidth: 25rem;\n\t}\n\t.modal__body {\n\t\tjustify-content: space-between;\n\t\tpadding: 0.5rem;\n\t\tpadding-inline-start: none;\n\t\tpadding-inline-end: none;\n\t}\n\t.modal__arrow {\n\t\twidth: 3rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal__arrow": `ModalImages_modal__arrow__r6kzj`,
	"modal__img": `ModalImages_modal__img__NBdQR`,
	"modal__body": `ModalImages_modal__body__akP0u`,
	"close__button": `ModalImages_close__button__UWPi0`
};
export default ___CSS_LOADER_EXPORT___;
