// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Reviews_reviews__pASmz {
	padding: 6.25rem 10.31rem;
	background: var(--grey-color, #fff8f6);
	display: flex;
	flex-direction: column;
	gap: 5rem;
}

.Reviews_reviews__header__MkTAG {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Reviews_slider__buttons__h0-3c {
	display: flex;
}

.Reviews_slider__buttons__h0-3c svg {
	cursor: pointer;
}

.Reviews_reviews__slider__WzkiM {
	display: flex;
	gap: 2rem;
}

@media (max-width: 460px) {
	.Reviews_reviews__pASmz {
		flex-direction: column;
		padding: 4.38rem 1.25rem;
		align-items: center;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/DefaultPage/Reviews/Reviews.module.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,sCAAsC;CACtC,aAAa;CACb,sBAAsB;CACtB,SAAS;AACV;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,SAAS;AACV;;AAEA;CACC;EACC,sBAAsB;EACtB,wBAAwB;EACxB,mBAAmB;CACpB;AACD","sourcesContent":[".reviews {\n\tpadding: 6.25rem 10.31rem;\n\tbackground: var(--grey-color, #fff8f6);\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 5rem;\n}\n\n.reviews__header {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n}\n\n.slider__buttons {\n\tdisplay: flex;\n}\n\n.slider__buttons svg {\n\tcursor: pointer;\n}\n\n.reviews__slider {\n\tdisplay: flex;\n\tgap: 2rem;\n}\n\n@media (max-width: 460px) {\n\t.reviews {\n\t\tflex-direction: column;\n\t\tpadding: 4.38rem 1.25rem;\n\t\talign-items: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviews": `Reviews_reviews__pASmz`,
	"reviews__header": `Reviews_reviews__header__MkTAG`,
	"slider__buttons": `Reviews_slider__buttons__h0-3c`,
	"reviews__slider": `Reviews_reviews__slider__WzkiM`
};
export default ___CSS_LOADER_EXPORT___;
