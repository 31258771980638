// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_error__text__Q7TRx {
  color: var(--red-color, #b3261e);
  font-family: "Noto Sans";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.2rem */
}

.Input_error__text__Q7TRx p {
  margin: 0;
}
.Input_input__area__iP5WT {
  width: 100%;
  position: relative;
  height: 4rem;
}

.Input_input__area__iP5WT svg {
  position: absolute;
  right: 0;
  top: 0.35rem;
}

.Input_title__UJO27 {
  color: var(--accent-color, #875b52);

  font-family: "Noto Sans";
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 2.2rem */
}

.Input_input__form__YrAy9 {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/Input/Input.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,wBAAwB;EACxB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC;;AAEA;EACE,SAAS;AACX;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,mCAAmC;;EAEnC,wBAAwB;EACxB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,WAAW;AACb","sourcesContent":[".error__text {\n  color: var(--red-color, #b3261e);\n  font-family: \"Noto Sans\";\n  font-size: 0.75rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 160%; /* 1.2rem */\n}\n\n.error__text p {\n  margin: 0;\n}\n.input__area {\n  width: 100%;\n  position: relative;\n  height: 4rem;\n}\n\n.input__area svg {\n  position: absolute;\n  right: 0;\n  top: 0.35rem;\n}\n\n.title {\n  color: var(--accent-color, #875b52);\n\n  font-family: \"Noto Sans\";\n  font-size: 1.375rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 160%; /* 2.2rem */\n}\n\n.input__form {\n  display: flex;\n  flex-direction: column;\n  gap: 0.3rem;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error__text": `Input_error__text__Q7TRx`,
	"input__area": `Input_input__area__iP5WT`,
	"title": `Input_title__UJO27`,
	"input__form": `Input_input__form__YrAy9`
};
export default ___CSS_LOADER_EXPORT___;
